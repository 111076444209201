import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuccessResponseComponent } from './views/purchase-response/success-response/success-response.component';
import { EventDetailsComponent } from './views/event-details/event-details.component';
import { EventPreviousEditionComponent } from './views/event-previous-edition/event-previous-edition.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { PaymentComponent } from './views/payment/payment.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RegistComponent } from './views/regist/regist.component';
import { ErrorResponseComponent } from './views/purchase-response/error-response/error-response.component';
import { CanceledComponent } from './views/purchase-response/canceled/canceled.component';
import { EventLastEditionComponent } from './views/event-last-edition/event-last-edition.component';
import { DonateComponent } from './views/donate/donate.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'details',
    component: EventDetailsComponent,
  },
  {
    path: 'event-album',
    component: EventPreviousEditionComponent,
  },
  {
    path: 'vip',
    component: PaymentComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'regist',
    component: RegistComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'success',
    component: SuccessResponseComponent,
  },
  {
    path: 'error',
    component: ErrorResponseComponent,
  },
  {
    path: 'canceled',
    component: CanceledComponent,
  },
  {
    path: 'lastEdition',
    component: EventLastEditionComponent,
  },
  {
    path: 'donate',
    component: DonateComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
