import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './views/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './component/navbar/navbar.component';
import { FooterComponent } from './component/footer/footer.component';
import { EventDetailsComponent } from './views/event-details/event-details.component';
import { TicketComponent } from './component/ticket/ticket.component';
import { PurchasedTicketComponent } from './component/purchased-ticket/purchased-ticket.component';
import { EventPreviousEditionComponent } from './views/event-previous-edition/event-previous-edition.component';
import { PaymentComponent } from './views/payment/payment.component';
import { LoginComponent } from './views/login/login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';


import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { RegistComponent } from './views/regist/regist.component';
import { ProfileComponent } from './views/profile/profile.component';
import { SuccessResponseComponent } from './views/purchase-response/success-response/success-response.component';
import { ErrorResponseComponent } from './views/purchase-response/error-response/error-response.component';
import { CanceledComponent } from './views/purchase-response/canceled/canceled.component';
import { EventLastEditionComponent } from './views/event-last-edition/event-last-edition.component';
import { DonateComponent } from './views/donate/donate.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    NavbarComponent,
    EventDetailsComponent,
    TicketComponent,
    EventPreviousEditionComponent,
    PaymentComponent,
    LoginComponent,
    RegistComponent,
    ProfileComponent,
    PurchasedTicketComponent,
    SuccessResponseComponent,
    ErrorResponseComponent,
    CanceledComponent,
    EventLastEditionComponent,
    DonateComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    NgxQRCodeModule,
    MatMenuModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
   
  ],
  providers: [BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
