import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketsComponent } from './tickets/tickets.component';
import { DialogAlertComponent } from './dialog-alert/dialog-alert.component';


@NgModule({
  declarations: [
    TicketsComponent,
    DialogAlertComponent,
  ],

  imports: [
    CommonModule,
  ],
  exports: [
    TicketsComponent,
    CommonModule,
  ],
})
export class SharedModule {}
