<div class="container mt-5">
    <div class="row">
        <div
            class="col-md-5 col-12 d-flex align-items-center  justify-content-center justify-content-md-end justify-content-lg-end">
            <img class="mr-3 mb-2 mb-md-0 mb-lg-0 event-cover" src=" assets/img/banner4.jpg"
                style="width: 180px; height: 110px;" alt="">

        </div>
        <div class="col-md-4 col-12 d-flex align-items-center justify-content-center">
            <article class="text-center text-md-left text-lg-left">
                <p class="event-name">Tribute of Afrohouse <br> Summer Edition 2022</p>
                <p class="event-date">31 de Julho de 2022, 16h Estádio do Restelo</p>
            </article>
        </div>
        <div class="col-md-3 col-12 d-flex flex-column align-items-center justify-content-center">
            <a target="blank" href="https://ticketline.sapo.pt/evento/tribute-of-afrohouse-summer-edition-64977"
                class="purchase-btn">Comprar</a>
            <p class="mt-2 price-tag">30€ - 60€</p>
        </div>
    </div>
</div>