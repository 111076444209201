<div class="container main-container">
    <div class="row h-100">
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img class="login-img" src="assets/img/login-logo.svg" alt="">
            <h2 class="mt-3 text-uppercase">Vip access</h2>
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div class="form-box ">
                <div class="text-center">
                    <h1>Registo</h1>
                    <p>Já tens conta? <span> <a href="/login" class="font-700">Faça login aqui!</a></span></p>
                </div>
                <div class="d-md-flex d-lg-flex d-block text-center pt-40">
                    <div class="mr-1 mb-3 mb-md-0 mb-lg-0">
                        <a class="social-btn font-700 " href=""> <img class="pr-25" src="assets/img/google-logo.svg"
                                alt="">
                            Google
                        </a>
                    </div>
                    <div class="mr-1  mb-3 mb-md-0 mb-lg-0">
                        <a class="social-btn font-700" href=""> <img class="pr-25" src="assets/img/apple-logo.svg"
                                alt="">
                            Apple
                        </a>
                    </div>
                    <div class="mr-1 mb-3 mb-md-0 mb-lg-0">
                        <a class="social-btn font-700" href=""> <img class="pr-25" src="assets/img/fb-logo.svg" alt="">
                            Facebook
                        </a>
                    </div>
                </div>
                <div class="pt-40">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Nome" aria-label="Username"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Email ou Telefone" aria-label="Email"
                            aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group mb-1">
                        <input type="password" class="form-control" placeholder="Password" aria-label="Password"
                            aria-describedby="basic-addon1">
                    </div>
                    <small class="warn">Tem que ter no mínimo 8 caracteres.</small>
                </div>
                <div
                    class="d-block d-md-flex d-lg-flex align-items-center justify-content-md-between  justify-content-lg-between justify-content-center pt-100">
                    <div class="form-check d-flex align-items-center">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Aceito os <a class="font-700" href="">
                                Termos e
                                Políticas</a></label>
                    </div>
                    <button class="regist-btn font-700 mt-3 mt-md-0 mt-lg-0">
                        Registar
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex justify-content-center align-items-center">
                <p class="text-white pt-4 pt-md-1 pt-lg-1 m-0" style="font-size: 15px; line-height: 20px;">
                    Esta página é dedicada exclusivamente a venda de bilhetes VIP do evento Tribute Of AfroHouse. Para
                    mais
                    detalhes sobre o evento entre em contacto com o Criador pelo contacto xxx@xxx.com ou pelo telefone
                    +351
                    999 999 999.
                </p>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-center">
                <img class=" w-100" src="assets/img/payment-methods.svg" alt="">
            </div>
        </div>
    </div>
</div>