import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle'; 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'base-project';
  constructor(private bnIdle: BnNgIdleService,private router: Router) {
 
  }
 
  // initiate it in your component OnInit
  ngOnInit(): void {
    this.bnIdle.startWatching(180).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        localStorage.removeItem('00_at_cv_b');
        this.router.navigate(['/vip']);
      }
    });
  }
}
