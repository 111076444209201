<app-navbar></app-navbar>
<div class="bck-btn">
    <a href="/" class="d-flex align-items-center" style="padding-top: 40px;">
        <img src="assets/img/back-btn.svg" alt="">
        <p class="text-white mt-1">Home</p>
    </a>
</div>
<div class="mb-5">
    <app-ticket></app-ticket>
</div>


<div class="container-fluid">
    <h2>Fotos</h2>
    <div class="row">
        <div class="col-md-6 d-flex justify-content-center align-items-center">
            <p class="msg">Opsssss <br> Sem fotos disponíveis ainda</p>
        </div>

        <div class=" col-md-6 d-flex justify-content-center align-items-center">
            <img class="astronauta-bg" src="assets/img/astronauta.svg" alt="">
        </div>
    </div>
</div>
<app-footer></app-footer>