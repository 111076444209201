<header>

    <nav class="navbar navbar-expand-lg navbar-light" #navbarCustom id="navbarCustom">
        <div class="container-fluid navbar-wrapper">
            <div class="logo-wrapper d-flex align-items-center">
                <a class="navbar-brand" href="#"><img class="" alt="tribute logo" src="assets/img/tribute-logo.svg"
                        alt=""></a>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span><img src="assets/img/hamburguer-menu.svg" style="width: 30px; height: 30px;" alt=""></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <div class="w-100">
                    <ul class="navbar-nav d-flex justify-content-between">
                        <div>
                            <ul class="navbar-nav">
                                <li class="nav-item active">
                                    <a class="nav-link" href="#event-description">Sobre <span
                                            class="sr-only">(current)</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#live">Live</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#event">Eventos</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#tickets">Bilhetes</a>
                                </li>
                            </ul>
                        </div>

                        <div
                            class="d-flex align-items-center mt-2 mt-md-0 mt-lg-0 ml-3 ml-md-0 ml-lg-0 mb-3 mb-md-0 mb-lg-0">
                            <li>
                                <a target="blank" href="http://www.muska.cv/"><img src="assets/img/logo-muska.svg"
                                        alt=""></a>
                            </li>
                            <li class="ml-4">
                                <a target="blank" href="https://www.instagram.com/tribute_of_afrohouse/"><img
                                        src="assets/img/ig-logo.svg" alt=""></a>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</header>