<div class="row" style="margin-top: 30px;">
    <div class="col-md-8 col-12 p-right">
        <div class="ticket-box">
            <h1>{{this.data.name}}</h1>
            <p class="description">{{this.data.description}}</p>
            <p class="pt-70 font-300">Não se esqueça de levar consigo o teste COVID válido</p>
        </div>
    </div>
    <div class="col-md-4 col-12 p-left">
        <div class="price-box">
            <div class="d-flex justify-content-between">
                <p>Preço Unitário</p>
                <p>{{this.data.price}}ECV</p>
            </div>
            <div class="d-flex justify-content-between pt-30">
                <p>Quantidade</p>
                <input class="ticket-qtt" min="0" max="10" type="number" (change)="total($event,this.data.price)">
            </div>
            <div class="d-flex justify-content-between pt-30">
                <p>Total</p>
                <p>{{priceTotal}}ECV</p>
            </div>
            <div class="text-center pt-30">
                <button class="purchase-btn w-100" (click)="purchase(this.data.price,this.data._id)">Comprar</button>
            </div>
        </div>
    </div>
</div>