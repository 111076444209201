import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Purchase, Ticket } from 'src/app/models/interface/ticket';
import { CastHanderService } from '../castHander/cast-hander.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private apiServer: CastHanderService) { }

  listTicket():Observable<[Ticket]>{

    return this.apiServer.getItem("ticket")
     
  }
  purchaseTicket(ticket:string,userid:string,qt:number):Observable<[Purchase]>{

    return this.apiServer.postItem({
      "ticket": ticket,
      "user": userid,
      "quantity": qt
  },"purchase")
     
  }
}