<div class="ticket-box">
    <h1 class="text-center text-md-left text-lg-left">{{this.data.ticket.name}}</h1>
    <div class="d-block d-md-flex d-lg-flex">
        <div class="text-center text-md-left text-lg-left">
            <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="this.data.code"
                cssClass="bshadow">
            </ngx-qrcode>
            <!--div class="text-center mt-2">
                <p>{{this.data.code}}</p>
            </div-->
        </div>
        <div class="pl-30">
            <div class="text-center text-md-left text-lg-left">
                <h1>{{this.data.ticket.description}}</h1>
                <!-- <h2>1 de Abril de 20200 18:00 Acesso VIP</h2> -->
            </div>
            <div class="pt-30 text-center text-md-left text-lg-left">
                <p>Consulte as normas de acesso ao evento <span class="font-700">www.coliseulisboa.com</span></p>
                <p>Este bilhete é válido para o evento Tribute of Afrohouse acesso VIP.</p>
            </div>
        </div>
    </div>
    <div
        class="text-center text-md-inherit text-lg-inherit d-block d-md-flex d-lg-flex justify-content-center justify-content-md-between justify-content-lg-between align-items-end pt-20">
        <p>Apresente este comprovativo a entrada</p>
        <img src="assets/img/ticket-logo.svg" class="mt-2 mt-md-0 mt-lg-0" alt="tribule-logo">
    </div>
</div>