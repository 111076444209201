import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-last-edition',
  templateUrl: './event-last-edition.component.html',
  styleUrls: ['./event-last-edition.component.scss']
})
export class EventLastEditionComponent implements OnInit {

  listImages:string[]=[];

  constructor() { }
  ngOnInit(): void {
    for(let i  = 1; i < 32; i++){
      this.listImages.push(`../upload/foto/foto%20(${i}).jpg`)

      console.log()
    }
  }


  loadMore(){
   
    if(this.listImages.length<=100){
      if(this.listImages.length<=50){
        for( let i = 1 ;i < 40; i++){
          this.listImages.push(`../upload/foto/foto%20(${i}).jpg`)   
      }
      }else if(this.listImages.length<=40){
        for( let i = 1 ;i < 20; i++){
          this.listImages.push(`../upload/foto/foto%20(${i}).jpg`) 
         }
      }else{
        for( let i = 1 ;i < 22; i++){
          this.listImages.push(`../upload/foto/foto%20(${i}).jpg`) 
         }
      }
    }
  
  }

}
