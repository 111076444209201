<footer>
    <div class="container-fluid">
        <hr>
        <div class="d-block d-md-flex d-lg-flex justify-content-between align-items-end links text-center ">
            <div class="mb-2 mb-md-0 mb-lg-0"><img src="assets/img/footer-tribute-logo.svg" alt=""></div>
            <div class="mb-2 mb-md-0 mb-lg-0">Phone: <br>+351 966 345 381</div>
            <div class="mb-2 mb-md-0 mb-lg-0">Email: <br>tributeIII@gmail.com</div>
            <div class="mb-2 mb-md-0 mb-lg-0">&copy; Tribute of AfroHouse</div>
            <div class="mb-3 mb-md-0 mb-lg-0 font-300">Powered by: <span style="font-weight:700">Bonako</span></div>
        </div>
    </div>
</footer>