<div class="container main-container">
    <div class="row h-100">
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img src="assets/img/login-logo.svg" class="responsive-widht mt-3 mt-md-0 mt-md-0" alt="logo">
            <h2 class="mt-3 text-uppercase">Vip access</h2>
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <div class="form-box mt-md-3">
                <div class="text-center">
                    <div class="center">
                        <mat-spinner [diameter]="30" *ngIf="spinner"></mat-spinner>
                    </div>

                    <h1>{{heardeTitle}}</h1>
                    <p>{{labelAction}} <span> <a (click)="switchStatus()" class="font-700">{{bntAction}}</a></span></p>
                </div>
                <div class="d-md-block d-lg-flex d-block text-center pt-40">
                    <div class="mb-3 mb-md-3 mb-lg-0">
                        <a class="social-btn font-700 mr-2" href=""> <img class="pr-25" src="assets/img/google-logo.svg"
                                alt="">
                            Google
                        </a>
                    </div>
                    <div class="mb-3 mb-md-3 mb-lg-0">
                        <a class="social-btn font-700 mr-2" href=""> <img class="pr-25" src="assets/img/apple-logo.svg"
                                alt="">
                            Apple
                        </a>
                    </div>
                    <div class="mb-3 mb-md-3 mb-lg-0">
                        <a class="social-btn font-700" href=""> <img class="pr-25" src="assets/img/fb-logo.svg" alt="">
                            Facebook
                        </a>
                    </div>
                </div>
                <form [formGroup]="formGroup">
                    <div class="pt-40">
                        <div class="input-group mb-3" *ngIf="this.statusLogin">
                            <input type="text" class="form-control" placeholder="Nome" aria-label="Username"
                                aria-describedby="basic-addon1" [formControl]="name">
                        </div>
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Email ou Telefone"
                                aria-label="Username" aria-describedby="basic-addon1" [formControl]="email">
                        </div>
                        <div class="input-group mb-1">
                            <input type="password" class="form-control" placeholder="Password" aria-label="Username"
                                aria-describedby="basic-addon1" [formControl]="password">
                        </div>
                        <small class="warn">Tem que ter no mínimo 8 caracteres.</small>
                    </div>
                    <small class="warnAlert" *ngIf="warStatus">{{warAlert}}</small>
                    <div class="d-block d-md-block d-lg-flex align-items-center justify-content-between pt-40">
                        <div class="form-check d-flex align-items-center">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1">Aceito os <a class="font-700" href="">
                                    Termos e
                                    Políticas</a></label>
                        </div>
                        <div class="text-center text-md-center text-lg-right">
                            <button class="lgn-btn font-700 mt-3 mt-md-3 mt-lg-0" (click)="verifyEmail()">
                                {{btnGo}}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row mt-3 mt-md-5 mt-lg-5">
            <div class="col-md-6 col-lg-6 col-12 d-flex align-items-center">
                <p class="text-white" style="font-size: 15px; line-height: 20px;">
                    Esta página é dedicada exclusivamente a venda de bilhetes VIP do evento Tribute Of AfroHouse. Para
                    mais
                    detalhes sobre o evento entre em contacto com o Criador pelo contacto tributeIII@gmail.com ou
                    pelo telefone +351 966 345 381.
                </p>
            </div>
            <div class="col-md-6 col-lg-6 col-12 d-flex justify-content-center align-items-center">
                <img class="w-100" style="height: 90px;" src="assets/img/payment-methods.svg" alt="">
            </div>
        </div>
    </div>
</div>