import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ticket } from 'src/app/models/interface/ticket';
import { PaymentService } from 'src/app/services/payment/payment.service';
// import { Ticket } from 'src/app/models/interface/ticket';
// import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  ticket: Ticket[] = [];
  name = ""
  loginStatus = false
  constructor(private listTicket : PaymentService,private router: Router) {}


  ngOnInit(): void {
    this.getTicket();
    this.getUser();
  }

  getTicket(){
    

    
    this.listTicket.listTicket().subscribe( value => {
      
      console.log('value data ',value )
      if(value != undefined){
        this.ticket = value
      }else{
      //  Alerta
      }
     })
  }


  priceTotal=0

total(event:any,price:number){
  this.priceTotal= price*event.target.value
}

totalCalculete(event:any,price:number):number{
  return price*event.target.value;
}


getUser(){

  if (localStorage.getItem('00_at_cv_b')) {
    const obj = JSON.parse(localStorage.getItem('00_at_cv_b')??"");
    this.name = obj.name
    this.loginStatus = true
  }else{
    this.loginStatus = false
    // no User 
  }

  

  
}


logout(){
  localStorage.removeItem('00_at_cv_b');
  window.location.reload();
}
login(){
  this.router.navigate(['/login']);
}
profile(){
  this.router.navigate(['/profile']);
}


}
