import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  @ViewChild('navbarCustom', { static: false })
  public navbarCustom!: ElementRef;
  close:boolean=false;
  constructor() { }

  ngOnInit(): void {
    $(document).ready( () => {
      const navbarCollapse = () => {
        if ($(this.navbarCustom.nativeElement).offset().top > 100) {
          $(this.navbarCustom.nativeElement).addClass('active');
        } else {
          $(this.navbarCustom.nativeElement).removeClass('active');
        }
      };
    
      // Collapse now if page is not at top
      navbarCollapse();
    
      // Collapse the navbar when page is scrolled
      $(window).scroll(navbarCollapse);
    
    });
  }
  closeNav(){
    this.close=true;
     }
}
