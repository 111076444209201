<app-navbar></app-navbar>

<div class="container-fluid">
    <a href="/" class="d-flex align-items-center" style="padding-top: 40px;">
        <img src="assets/img/back-btn.svg" alt="">
        <p class="text-white mt-1">Home</p>
    </a>

    <div class="row">
        <div class="col-md-7">

        </div>
        <div class="col-md-5">

        </div>
    </div>

    <p class="description">Vamos Mater o Festival aberto, e suportar muitoa mais djs do nosso genero favorito de musica.
    </p>


</div>

<app-footer></app-footer>