<div class="container-fluid">
    <div class="row">
        <div class="col-6 col-md-10 col-lg-10 d-flex justify-content-center">
            <img src="assets/img/tribute-logo.svg" alt="">
        </div>
        <div class="col-6 col-md-2 col-lg-2">
            <div class="dropdown text-right">

                <button mat-button [matMenuTriggerFor]="menu" class="btn btn-secondary dropdown-toggle">{{this.name !=
                    "" ? this.name:'Login'}}</button>
                <mat-menu #menu="matMenu">

                    <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>

            </div>
        </div>
    </div>
    <div class="pt-80">
        <a href="/vip" class="d-flex align-items-center">
            <img class="back-btn" src="assets/img/go-back.svg" alt="">
            <p class="ml-2 mb-0 back ">Back</p>
        </a>
    </div>
    <div class="pt-45">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-5 alignment">
                <div class="d-block d-md-flex d-lg-flex">
                    <div class="avatar-wrap">
                        <img class="avatar rounded-circle" [src]="image?image:'assets/img/avatar.jpg'" alt="avatar">
                    </div>
                    <div class="pl-25 mt-3 mt-md-0 mt-lg-0">
                        <p class="label">Nome</p>
                        <p class="user-name">{{name}}</p>
                        <div class="pt-25 my-2 my-md-0 my-lg-0">
                            <a href="" data-toggle="modal" data-target="#exampleModal" class="change-password ">Change
                                Password</a>
                        </div>
                        <label class="mt-2 px-4 py-2 upload-btn" for='upload'>Atualizar foto</label>
                        <input id="upload" type="file" (change)="fileChange($event)" name="file" />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-7 mt-md-3 mt-lg-0 mt-0 alignment">
                <p class="label">Email</p>
                <p class="user-name">{{email}}</p>
            </div>
        </div>
    </div>

    <div class="pt-45">
        <h1>Os teus Bilhetes</h1>
        <div class="pt-45" *ngFor="let item of this.ticket">
            <app-purchased-ticket [data]="item"></app-purchased-ticket>
        </div>
    </div>
    <div class="pt-80">
        <hr>
        <div class="d-block d-md-flex d-lg-flex justify-content-between align-items-end text-center ">
            <div class="mb-2 mb-md-0 mb-lg-0"><img src="assets/img/footer-tribute-logo.svg" alt=""></div>
            <div class="d-block d-md-flex d-lg-flex">
                <div class="mb-2 mb-md-0 mb-lg-0 font-14  text-white mr-3">Phone: <br>+351 966 345 381</div>
                <div class="mb-2 mb-md-0 mb-lg-0 font-14 text-white">Email: <br>tributeIII@gmail.com</div>
            </div>
        </div>
    </div>
</div>


<!--------- Change Password Modal ---------->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="padding: 10px 50px;">
            <div class="modal">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h1 style="color: #000;">Mudar Password</h1>
                <div class="center">
                    <mat-spinner [diameter]="30" *ngIf="spinner"></mat-spinner>
                </div>
                <div style="padding-top: 30px;">
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Password Atual</label>
                        <input type="password" class="form-control" id="exampleFormControlInput1" placeholder="********"
                            [formControl]="password" required>

                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlInput2">Novo Password</label>
                        <input type="password" class="form-control" id="exampleFormControlInput2" placeholder="********"
                            [formControl]="newPassword" required>

                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlInput3">Repetir Password</label>
                        <input type="password" class="form-control" id="exampleFormControlInput3" placeholder="********"
                            [formControl]="CNewPassword" required>
                    </div>
                    <small class="warnAlert" *ngIf="warStatus">{{warAlert}}</small>
                </div>
                <div class="text-center mt-4">
                    <button class="change-btn px-5 py-2" (click)="changePassword()">Concluir</button>
                </div>
            </div>

        </div>
    </div>
</div>