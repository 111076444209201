<app-navbar></app-navbar>
<div class="bck-btn">
    <a href="/" class="d-flex align-items-center" style="padding-top: 40px;">
        <img src="assets/img/back-btn.svg" alt="">
        <p class="text-white mt-1">Home</p>
    </a>
</div>
<div class="mb-5">
    <app-ticket></app-ticket>
</div>


<div class="container-fluid">
    <div>
        <h2>Fotos</h2>
        <div class="row mb-120 mt-30">
            <div class="col-md-3" *ngFor="let img of listImages">
                <a data-fancybox="gallery" href={{img}}>
                    <img width="100%" class="mb-2 photo" [src]="img" alt="">
                </a>
            </div>
            <div class="col-md-3" *ngIf="this.listImages.length<=33">
                <button (click)="loadMore()" class="box w-100 d-flex justify-content-center align-items-center">
                    Ver mais
                </button>
            </div>

        </div>
    </div>
</div>
<app-footer></app-footer>