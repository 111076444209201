import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  
  
    listImages:string[]=[];
  
  constructor() {

   }


  ngOnInit(): void {
    for(let i  = 0; i < 11; i++){
      this.listImages.push(`assets/img/fotos${i + 1}.jpg`)
    }
  }



  loadMore(){
   
    if(this.listImages.length<=33){
      if(this.listImages.length<=11){
        for( let i = 0 ;i < 11; i++){
          this.listImages.push(`assets/img/IMG_8${i + 1}.jpg`)   
      }
      }else if(this.listImages.length<=22){
        for( let i = 0 ;i < 11; i++){
          this.listImages.push(`assets/img/IMG_819${i + 1}.jpg`) 
         }
      }else{
        for( let i = 0 ;i < 22; i++){
          this.listImages.push(`assets/img/IMG_${i + 1}.jpg`) 
         }
      }
    }
  
  }

}
