import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { PurchaseTicket, Ticket } from 'src/app/models/interface/ticket';
import { AutenticationService } from 'src/app/services/autentication/autentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  name = "";
  email = "";
  userId = ""
  warAlert  = ""
  image!: any;
  spinner = false
  warStatus  = false
  ticket: PurchaseTicket[] = [];
  password = new FormControl('password', [Validators.required,]);
  newPassword = new FormControl('newPassword', [Validators.required,]);
  CNewPassword = new FormControl('cNewPassword', [Validators.required,]);

    imageError: any;
    isImageSaved = false;
    cardImageBase64 =  "";

  constructor(private userServer: AutenticationService,private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getUser();
    this.route.queryParams
      .subscribe(params => {  
        if(params.code != undefined){    
         this.autoLogin(params.code)
        }
      }
    );

  }


  getUser(){

    if (localStorage.getItem('00_at_cv_b')) {
      const obj = JSON.parse(localStorage.getItem('00_at_cv_b')??"");
      var splitted = obj.email.split("@", 2); 
      this.email = obj.email.charAt(0)+"•••••@"+splitted[1];
      this.name = obj.name
      this.userId = obj._id
      this.image = obj.image

      console.log('this.image: ', this.image);
      this.getTicket(obj._id);
    }else{
      
      // no User 
    }


    
  }

  getTicket(userId:String){
    
    this.userServer.listTicket(userId).subscribe( value => {
      
      console.log('value data ',value )
      if(value != undefined){
        this.ticket = value
      }else{
      //  Alerta
      }
     })
  }

  autoLogin(code:any){
    
    this.userServer.userAutoLogin(code).subscribe( value => {
      
      console.log('value data ',value )
      if(value.email != undefined){ 
        localStorage.setItem('00_at_cv_b', JSON.stringify(value));        
        this.router.navigate(['/profile']);
        this.getUser();
        // window.location.reload();
        
      }else{
        this.warStatus  = true;
        this.warAlert = value
      }
     })
  }

  logout(){
    localStorage.removeItem('00_at_cv_b');
    this.router.navigate(['/vip']);
  }
  openModal(id: string) {
    // this.modalService.open(id);
  }

  changePassword() {
    
    if(this.password.value != "" && this.newPassword.value != "" && this.CNewPassword.value == this.newPassword.value){

      this.spinner = true;
      this.userServer.userNewPassword(this.password.value,this.newPassword.value,this.userId).subscribe(value => {

        this.spinner = false;
        if(value.email != undefined){ 
          localStorage.setItem('00_at_cv_b', JSON.stringify(value));        
          window.location.reload();
          // this.router.navigate(['/vip']);
          
        }else{
          this.warStatus  = true;
          this.warAlert = value
        }
        
        
       })

    }else{
      //alert
      this.warAlert = "value "
    }

   
  }

  fileChange(event:any): void {
    const fileList: FileList = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
   
    reader.onload = (_event) => {
			this.image = reader.result;
      this.userServer.uploadFile("update/"+this.userId,reader.result).subscribe(value =>{
        localStorage.setItem('00_at_cv_b', JSON.stringify(value)); 
         console.log(value)
      })
		}
    
    reader.onerror = function (error) {
      console.log('Error: ', error);
      // this.image = reader.result;
    };
    

}

removeImage() {
  // this.cardImageBase64 = null;
  this.isImageSaved = false;
}


}
