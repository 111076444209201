<app-navbar></app-navbar>
<div class="container-fluid bg-banner">
    <div>
        <img class="bg-home" src="assets/img/bg-home.svg">
        <img class="astronauta-bg" src="assets/img/astronauta.svg">

    </div>
    <div class="banner-text">
        <h1 class="mb-4">
            TRIBUTE OF AFROHOUSE <br> Summer Edition 2022
        </h1>
        <h3 class="mb-4">
            31 de Julho de 2022
        </h3>
        <h3 class="mb-4">
            Estádio do Restelo
        </h3>

        <div class="my-3 my-md-5 my-lg-5">
            <a target="blank" href="https://ticketline.sapo.pt/evento/tribute-of-afrohouse-summer-edition-64977"
                class="purchase-btn">Comprar Bilhetes</a>
            <p class="mt-3 price-tag padding-price">30€ - 60€</p>
        </div>
    </div>
</div>
<div id="event-description">
    <div>
        <img class="moon-bg" src="assets/img/moon.svg" alt="">
        <img class="stars-bg" src="assets/img/stars.svg" width="100%" alt="">
    </div>
    <div class="spacement">
        <div>
            <h2>Descrição do Evento</h2>
            <p class="description mt-4">Tribute of Afrohouse, é a terceira edição do maior festival do género em
                Portugal.
                Depois do sucesso das duas anteriores edições, o festival vai juntar, a 1 de Abril, no Coliseu de
                Lisboa,
                grandes nomes nacionais e internacionais do estilo emergente.
                Com um cartaz de luxo, esta é uma festa para todos os fãs do house, afro tech , afrohouse e afrodeep.
            </p>
        </div>
        <div class="pt-60" id="live">
            <h2>Live</h2>
            <iframe class="mt-4 event-cover" style="border-radius: 40px;"
                src="https://vimeo.com/event/1853426/embed/bebc22b722" height="530px" width="100%"></iframe>
            <div class="d-flex justify-content-end mt-3 mr-2">
                <p style="font-weight: 400;">Powered by <a target="_blank" href="http://www.muska.cv/"> <img
                            src="assets/img/logo-muska.svg" alt="" class="mx-1"> <span
                            style="color: #FAFAFA;">Muska</span></a></p>

            </div>
        </div>
    </div>
    <div>
        <img class="moon-event-bg" src="assets/img/moon2.svg" alt="">
        <div class="spacement" id="event">
            <h2>Eventos</h2>
            <div class="row pt-60">
                <div class="col-md-6 col-12 col-lg-4 mb-3">
                    <div>
                        <a href="/event-album" data-toggle="tooltip" data-placement="top"
                            title="Clique para mais detalhes">
                            <img class="event-card" width="100%" src="assets/img/event-cover1.svg" alt="">
                            <article class="mt-2">
                                <p class="event-name">Tribute of Afrohouse <br> I edition</p>
                                <p class="event-date">13 de Julho de 2018</p>
                            </article>
                        </a>
                    </div>
                </div>
                <div class="col-md-4 col-md-6 col-12 col-lg-4 mb-3">
                    <a href="/details" data-toggle="tooltip" data-placement="top" title="Clique para mais detalhes">
                        <img width="100%" class="event-card" src="assets/img/event-cover2.svg" alt="">
                        <article class="mt-2">
                            <p class="event-name">Tribute of Afrohouse <br> II edition</p>
                            <p class="event-date">26 de Novembro de 2021</p>
                        </article>
                    </a>
                </div>
                <div class="col-md-4 col-12 col-lg-4 mb-3">
                    <a href="/lastEdition" data-toggle="tooltip" data-placement="top" title="Clique para mais detalhes">
                        <img class="event-card" width="100%" src="assets/img/event-cover3.svg" alt="">
                        <article class="mt-2">
                            <p class="event-name">Tribute of Afrohouse <br> Summer Edition</p>
                            <p class="event-date">31 de Julho de 2022, 16h Estádio do Restelo</p>
                        </article>
                    </a>
                </div>
                <div class="col-md-4 col-12 col-lg-4 mb-3">
                    <a href="/event-album" data-toggle="tooltip" data-placement="top" title="Clique para mais detalhes">
                        <img class="event-card" width="100%" src="assets/img/banner4.jpg" alt="">
                        <article class="mt-2">
                            <p class="event-name">Tribute of Afrohouse <br>Summer Edition 2022</p>
                            <p class="event-date">31 de Julho de 2022, 16h Estádio do Restelo</p>
                        </article>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div id="tickets" class="spacement">
        <h2>Bilhetes</h2>
        <app-ticket></app-ticket>
    </div>
</div>
<app-footer></app-footer>