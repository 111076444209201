import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-error-response',
  templateUrl: './error-response.component.html',
  styleUrls: ['./error-response.component.scss']
})
export class ErrorResponseComponent implements OnInit {
  msg = ""
  constructor(private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getUrl();

  }

  getUrl() {
    this.route.queryParams
      .subscribe(params => {      
        this.msg = params.msg;
      }
    );
  }
}