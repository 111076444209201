<div class="container-fluid">
    <section>
        <div class="row">
            <div class="col-6 col-md-10 col-lg-10 d-flex justify-content-center">
                <a href=""><img src="assets/img/tribute-logo.svg" alt=""></a>
            </div>
            <div class="col-6 col-md-2 col-lg-2">
                <div class="dropdown text-right">

                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-secondary dropdown-toggle">{{this.name
                        != "" ? this.name:'Login'}}</button>

                    <mat-menu #menu="matMenu">
                        <button *ngIf="this.loginStatus" mat-menu-item href="/profile"
                            (click)="profile()">Profile</button>
                        <button *ngIf="this.loginStatus" mat-menu-item (click)="logout()">Logout</button>
                        <button *ngIf="!this.loginStatus" mat-menu-item (click)="login()">Login</button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <img class="payment-bg pt-30" src="assets/img/payment-bg.svg" alt="">
        <article class="event-details">
            <div class="d-flex align-items-center">
                <div class="border-right">
                    <div class="text-center" style="padding-right: 15px;">
                        <p class="font-30">1</p>
                        <p class="font-20">Abril</p>
                    </div>
                </div>
                <div>
                    <h1>Tribute of AfroHouse III</h1>
                </div>
            </div>
            <div class="pt-30">
                <h1>Coliseu Lisboa</h1>
                <div class="d-flex">
                    <small class="mr-4">Sábado, 18:00h</small>
                    <small>Coliseu, Lisboa</small>
                </div>
                <small class="pt-3">$100 - $150</small>
            </div>
        </article>
    </section>
    <section class="pt-70">

        <!-------------------------- Bilhete Vip -------------------------->

        <h1>Bilhetes VIP</h1>
        <div *ngFor="let item of this.ticket" class="row" style="margin-top: 30px;">
            <app-tickets [data]="item"></app-tickets>
        </div>


        <!-------------------- Bilhete Premium -------------------->

    </section>
    <section>
        <h1>Info</h1>

        <p> Tribute of Afrohouse, é a terceira edição do maior festival do género em Portugal. Depois do sucesso das
            duas
            anteriores edições, o festival vai juntar, a 1 de Abril, no Coliseu de Lisboa, grandes nomes nacionais e
            internacionais do estilo emergente. Com um cartaz de luxo, esta é uma festa para todos os fãs do house, afro
            tech , afrohouse e afrodeep.</p>

        <p> No âmbito das medidas nacionais de combate à COVID-19, deve ser apresentado à entrada um dos seguintes
            documentos:</p>

        <p> - Teste rápido de antigénio (TRAg), verificado por entidade certificada e realizado até 48 horas antes, com
            resultado negativo;</p>

        <p> - Teste laboratorial (PCR) realizado até 72 horas antes, com resultado negativo;</p>

        <p> - Auto-teste, adquirido pelo próprio, a realizar no local do evento, sob supervisão e verificação do staff
            do evento. Na hora anterior ao início do evento e com entrada imediata. (Sujeito a demora em situações de
            elevada afluência);</p>

        <p> - Certificado Digital de Recuperação COVID-19;</p>

        <p> - Certificado Digital de Vacinação anti-COVID-19 válido (com esquema vacinal completo com dose de reforço
            administrada há mais de 14 dias).</p>

        <p> Menores de 12 anos de idade ficam isentos de apresentar documentos.
        </p>
    </section>
    <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex align-items-center justify-content-">
                <p>Esta página é dedicada exclusivamente a venda de bilhetes VIP do evento Tribute Of AfroHouse. Para
                    mais
                    detalhes sobre o evento entre em contacto com o Criador pelo contacto tributeIII@gmail.com ou
                    pelo telefone +351 966 345 381.</p>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-start">
                <img class="w-100" style="height: 90px;" src="assets/img/payment-methods.svg" alt="">
            </div>
        </div>
    </div>


    <!------- Footer -------->

    <div class="pt-70">
        <hr>
        <div class="d-block d-md-flex d-lg-flex justify-content-between align-items-end text-center ">
            <div class="mb-2 mb-md-0 mb-lg-0"><img src="assets/img/footer-tribute-logo.svg" alt=""></div>
            <div class="d-block d-md-flex d-lg-flex">
                <div class="mb-2 mb-md-0 mb-lg-0 font-14 mr-3">Phone: <br>+351 966 345 381</div>
                <div class="mb-2 mb-md-0 mb-lg-0 font-14">Email: <br>tributeIII@gmail.com</div>
            </div>
        </div>
    </div>
</div>