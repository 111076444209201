<div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="response-box">
        <img src="assets/img/ticket-logo.svg" alt="tribute-logo">
        <img class="pt-30 success" src="assets/img/success-img.svg" alt="success">
        <article class="text-center pt-30">
            <h1>Obrigada pela Compra!</h1>
            <p>Pagamento efetuado com sucesso!</p>
        </article>
        <div class="pt-30">
            <a href="/vip" class="purchase-btn">Comprar mais</a>
        </div>
    </div>
</div>