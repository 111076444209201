import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse,} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, Subject, from} from 'rxjs';
import { catchError, retry ,map} from 'rxjs/operators';
import { PurchaseTicket, Ticket } from 'src/app/models/interface/ticket';
import { CastHanderService } from '../castHander/cast-hander.service';


@Injectable({
  providedIn: 'root'
})
export class AutenticationService {

  constructor(private http: HttpClient,private apiServer: CastHanderService) { }

  verifyEmail(email:String):Observable<any>{

    
    return this.apiServer.postItem({"email": email},"verify")
     
  }

  userLogin( email:String,password:String):Observable<any>{
    
    return this.apiServer.postItem({"email": email,"password":password},"login")

  }

  userAutoLogin( code:String):Observable<any>{
    
    return this.apiServer.getItem("autoLogin/"+code)

  }
  

  userRegister( email:String,password:String,name:String):Observable<any>{
    
    return this.apiServer.postItem({"email": email,"password":password,"name":name},"register").pipe(
      
      catchError((error) => {
        console.log('error is intercept request')
        return throwError(error);
      })
    );  

  }
  userEdit( name:String,phone:String,dataNas:String):Observable<any>{
    
    return this.apiServer.putItem({"fullName": name,"phone":phone,"birthDate":dataNas},"client/edit/",)

  }

  userNewPassword( password:String,newPassword:String,userId:String):Observable<any>{
    
    return this.apiServer.putItem({"password": password,"newPassword":newPassword},"changePassword/"+userId,)

  }

  listTicket(idUser:String):Observable<[PurchaseTicket]>{

    return this.apiServer.getItem("myPurchases/"+idUser)
     
  }

  // file from event.target.files[0]
  uploadFile(url: string, file: any): Observable<HttpEvent<any>> {

    return this.apiServer.putItem({"image":file},url)

  }

  

}