import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { DialogAlertComponent } from '../dialog-alert/dialog-alert.component';

@Component({
  selector: 'app-tickets',
  templateUrl: 'tickets.component.html',
  styleUrls: ['tickets.component.css']
})
export class TicketsComponent {

  @Input() data: any;
  
  constructor(private listTicket : PaymentService,private router: Router,public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogAlertComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  priceTotal=0
  qt = 0 

  total(event:any,price:number){
    this.qt = event.target.value
    this.priceTotal = price*event.target.value
  }

  purchase(price:number,ticket:string){

    if (localStorage.getItem('00_at_cv_b')) {
      const obj = JSON.parse(localStorage.getItem('00_at_cv_b')??"");
      console.log('value email_verified  ',obj.email_verified)

      if(obj.email_verified == true){
        this.listTicket.purchaseTicket(ticket,obj._id,this.qt).subscribe( value => {
      
          console.log('value data ',value )
          if(value != undefined){
            window.open(`https://bwenzi.com:3000/api/v1/sisp/payment/TRIBUTO?purchase_id=${value[0].purchaseToken}&lang=pt&price=${this.priceTotal/1000}`);
            
            
    
          }else{
          //  Alerta
          }
         })
      }else{
        // validar email
        this.openDialog();
      }
    


    }else{
      // login
      this.router.navigate(['/login']);
    }


    
  }

  
}
