import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse,} from '@angular/common/http';
import { Observable, throwError, of, Subject, from} from 'rxjs';
import { catchError, retry ,map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ticket } from 'src/app/models/interface/ticket';

@Injectable({
  providedIn: 'root'
})
export class CastHanderService {

  constructor(private http: HttpClient) { }

  getItem(url:String): Observable<[any]>{
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.get<[any]>(environment.BASE_API_URL + url,{'headers': headers})
     .pipe(
       catchError((error) => {
         console.log('error is intercept')
        return [error.error];
       })
     )
 
   }

  postItem(parametro: any,url:String): Observable<any>{
    console.log('error is intercept ',url);
    console.log('error is intercept ',parametro);
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

    

    return this.http.post<any>(environment.BASE_API_URL + url,parametro,{'headers': headers})
    .pipe(
      // map((value)=>{
      //   console.log('error is intercept request')
      //   console.error('error',value);
      //   return value;
      // })
      catchError((error) => {
        console.log('error is intercept request')
        return [error.error];
       
      })
    );
  }
  putItem(parametro: any,url:String): Observable<any>{
    const headers= new HttpHeaders()
    .set('content-type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');

    console.log(parametro)

    return this.http.put<any>(environment.BASE_API_URL + url,parametro,{'headers': headers})
    .pipe(
      // map((value)=>{
      //   console.log('error is intercept request')
      //   console.error('error',value);
      //   return value;
      // })
      catchError((error) => {
        console.log('error is intercept request')
        // this.errorpageRout(error);
        return [error.error];
      })
    );
  }

}

