import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AutenticationService } from 'src/app/services/autentication/autentication.service';
import { DialogAlertComponent } from 'src/app/shared/dialog-alert/dialog-alert.component';
import { VerifyAutentication } from '../../models/enums/enums';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ]),
    
  });
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required,]);
  name = new FormControl('', [Validators.required,]);
  validatyStatus : VerifyAutentication = VerifyAutentication.login;
  heardeTitle = "Login";
  bntAction = "Regista Aqui!"
  btnGo = "Entrar"
  labelAction = "Não tens uma conta?"
  warAlert  = ""
  spinner = false
  statusLogin = false
  warStatus  = false
  

  constructor(private userServer: AutenticationService,private router: Router,public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogAlertComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    
  }

  switchStatus(){
    if (this.validatyStatus == VerifyAutentication.register){
      this.heardeTitle = "Login";
      this.bntAction = "Regista Aqui!"
      this.labelAction = "Não tens uma conta?"
      this.btnGo = "Entrar"
      this.statusLogin = false
      this.validatyStatus = VerifyAutentication.login
    }else{
      this.heardeTitle = "Registo";
      this.bntAction = "Faça login aqui!"
      this.labelAction = "Já tens conta?"
      this.btnGo = "Regista"
      this.statusLogin = true
      this.validatyStatus = VerifyAutentication.register
    }

  }

  verifyEmail(){
    this.warStatus  = false;
    switch (this.validatyStatus) {
      case VerifyAutentication.login: 
        if (this.email.value != "" && this.password.value != ""){
          this.spinner = true
          this.sendLogin()
        }
        break
      case VerifyAutentication.register:
        if(this.email.valid){
          if (this.password.value != "" && this.name.value != ""){
            this.spinner = true
            this.sendRegister()
          } 
        }else{
          this.warStatus  = true;
          this.warAlert = "This mail is not valid"
        }
       
        
        break
      default: 
      if (this.email.value != ""){
        // this.spinner = true
        // this.emailValiDate()
      }
        
        break
    }
  }

  sendLogin() {

    this.userServer.userLogin(this.email.value,this.password.value).subscribe(value => {
      this.spinner = false;
      if(value.email != undefined){ 
        localStorage.setItem('00_at_cv_b', JSON.stringify(value));        
        // window.location.reload();
        this.router.navigate(['/vip']);
        
      }else{
        this.warStatus  = true;
        this.warAlert = value
      }

      
      
     })
  }
  sendRegister() {
   
    this.userServer.userRegister(this.email.value,this.password.value,this.name.value).subscribe(value => {
      this.spinner = false;
      
      if(value.email != undefined){ 
        localStorage.setItem('00_at_cv_b', JSON.stringify(value));        
        // window.location.reload();
        this.router.navigate(['/vip']);
        this.openDialog();
        
      }else{
        this.warStatus  = true;
        this.warAlert = value
      }
      
     })
  }

}
