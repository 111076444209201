<div class="container-fluid d-flex justify-content-center align-items-center">
    <div class="response-box">
        <img src="assets/img/ticket-logo.svg" alt="tribute-logo">
        <img class="pt-30 error" src="assets/img/error.svg" alt="error">
        <article class="text-center pt-30">
            <h1>{{this.msg}}</h1>
            <p>Tente novamente!</p>
        </article>
        <div class="pt-30">
            <a href="/vip" class="purchase-btn">Comprar mais</a>
        </div>
    </div>
</div>